<template>
  <loader v-if="loading" :loading-message="loadingMessage" />

  <form
    v-else
    autocomplete="off"
    id="event-form"
    class="column form-component"
    @submit.prevent="submitEventForm"
  >
    <p>Fields marked with (<span class="error--text">*</span>) are required.</p>

    <label for="eventName">
      <span :class="requiredField('name')">Event Name</span>
      <input
        name="eventName"
        @input="clearErrors"
        placeholder="Enter Event Name"
        type="text"
        v-model="form.name"
      />
    </label>

    <!-- Pricing -->
    <event-form-pricing :form-data="form" @change="appendFormData" />

    <!-- Instructors -->
    <event-form-teachers
      v-if="isAdminUser"
      :teachers="teachers"
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Schedule -->
    <event-form-schedule :form-data="form" @change="appendFormData" />

    <!-- Category/Status -->
    <event-form-participants :form-data="form" @change="appendFormData" />

    <!-- Image -->
    <event-form-image :form-data="form" @change="appendFormData" />

    <label for="eventLink">
      <span class="h6">
        <i class="accent--text fas fa-map-marked-alt"></i>
        <span>&nbsp;Event Link</span>
      </span>

      <input
        type="text"
        name="eventLink"
        @input="clearErrors"
        v-model="form.eventLink"
        placeholder="Paste Event Link here"
      />
    </label>

    <!-- Zoom Settings -->
    <event-form-zoom :form-data="form" @change="appendFormData" />

    <!-- Description -->
    <event-form-description :form-data="form" @change="appendFormData" />

    <!-- Form Controls -->
    <div class="form-controls">
      <!-- Submit -->
      <button
        class="wide"
        :class="{ disabled: error, 'button--outline grow': Boolean(event) }"
        type="submit"
        :disabled="error !== null"
      >
        {{ error ? " ... " : `${event ? "Update" : "Create"} Event` }}
      </button>

      <!-- Delete -->
      <button
        v-if="event"
        class="outline error--text"
        type="button"
        @click.prevent="confirmDelete = true"
      >
        <i class="fas fa-times-square"></i>
        Delete
      </button>
    </div>

    <p v-if="error" class="error--text">{{ error }}</p>

    <hr class="divider divider--lg" />

    <modal-component
      v-if="event"
      :title="`Delete '${data.name}'?`"
      :visible="confirmDelete"
      @close="confirmDelete = false"
    >
      <confirm-delete-event
        :error="error"
        :event="data"
        @confirm-delete="deleteEvent"
      />
    </modal-component>
  </form>
</template>

<script>
import {
  eventFormReset,
  eventCategories
} from "../helpers/classes-events.helpers";
// Component Mixins
import EventFormsMixin from "./mixins/event-forms.mixin";
// Components
import EventFormImage from "./EventForm.Image.vue";
import EventFormParticipants from "./EventForm.Participants.vue";
import EventFormSchedule from "./EventForm.Schedule.vue";
import EventFormTeachers from "./EventForm.Teachers.vue";
import EventFormZoom from "./EventForm.Zoom.vue";
import EventFormPricing from "./EventForm.Pricing.vue";
import Loader from "./Loader.vue";
import ModalComponent from "./ModalComponent.vue";
import ConfirmDeleteEvent from "./ConfirmDeleteEvent.vue";
import EventFormDescription from "./EventForm.Description.vue";
import { getUserById } from "../models/user";

export default {
  name: "EventForm",

  components: {
    ConfirmDeleteEvent,
    EventFormDescription,
    EventFormImage,
    EventFormParticipants,
    EventFormPricing,
    EventFormSchedule,
    EventFormTeachers,
    EventFormZoom,
    Loader,
    ModalComponent
  },

  mixins: [EventFormsMixin],

  data: () => ({ eventCategories }),

  watch: {
    "$route.path": function onRouteChange() {
      this.event = null;
      this.form = eventFormReset();
      this.fetchRouteData();
    }
  },

  methods: {
    /**
     * @override Overrides `FormsMixin.appendFormData`
     */
    appendFormData(data) {
      this.form = { ...this.form, ...data };
      this.clearErrors();

      // Retain an existing zoom link
      if (!this.event) return;

      if (data.zoomLink === null && Boolean(this.event.zoomLink)) {
        this.form.zoomLink = this.event.zoomLink;
      }
    },

    async hydrate(data) {
      this.event = data;
      // set date for UI components
      const scheduledDate = this.eventDate.toFormat("yyyy-MM-dd'T'T");
      const form = { ...data, scheduledDate };
      const { assignedTeacher } = data;
      const assignedTeacherId = assignedTeacher.id || assignedTeacher;

      if (assignedTeacher) {
        const { zoomUserId } = await getUserById(assignedTeacherId);
        form.zoomUserId = zoomUserId;
      }

      this.form = form;
      this.hydrateTeachers(data);
      this.stopLoading();
    }
  }
};
</script>
