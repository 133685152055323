<template>
  <fieldset class="event-form-pricing flex wrap">
    <legend class="h6">
      <i class="accent--text fas fa-money-bill-wave"></i>
      {{ type }} Pricing
    </legend>

    <!-- 'Free [Event, Class]/Create Product' checkbox -->
    <label
      v-if="!formData.productId"
      for="createProduct"
      class="flex wide"
      @click.stop="onCreateProduct"
    >
      <input
        type="checkbox"
        name="createProduct"
        :checked="!form.createProduct"
      />
      <hr class="divider divider--vertical" />
      <span
        :class="{
          'grey--text': form.createProduct,
          'success--text': !form.createProduct
        }"
      >
        &nbsp;This {{ type.toLowerCase() }}
        <b v-if="!form.createProduct">is free to all beautiful souls</b>
        <b v-else>has an entry cost</b>
      </span>
      <hr class="divider divider--vertical" />
      <i
        class="fas"
        :class="{
          'fa-heart error--text': !form.createProduct,
          'fa-money-bill grey-light--text': form.createProduct
        }"
      ></i>
    </label>

    <!-- Fields -->
    <template v-if="form.createProduct || formData.productId">
      <!-- Select Product -->
      <label for="eventProduct" class="col-33">
        <span class="h6">
          <i class="accent--text fas fa-list-alt"></i>
          <span>&nbsp;(Optional) Previous offering:</span>
        </span>
        <select
          name="eventProduct"
          @change="onProductId($event.target.value)"
          :value="form.productId"
        >
          <option :value="null">Select an Event:</option>
          <optgroup label="Previous Events">
            <option v-for="p in stripeProducts" :key="p.id" :value="p.id">
              {{ p.name }}
            </option>
          </optgroup>
        </select>
      </label>

      <hr class="divider divider--vertical" />

      <!-- Short description -->
      <label for="shortDescription" class="col-33">
        <span :class="requiredField('shortDescription')">
          <i class="accent--text fas fa-list-alt"></i>
          <span>&nbsp;Short Description</span>
          <span class="grey--text" v-if="form.shortDescription">
            ({{ 120 - form.shortDescription.length }}/120)
          </span>
        </span>

        <input
          name="shortDescription"
          :disabled="disableFields"
          @input="emitFormData"
          maxlength="120"
          placeholder="e.g. Single event; billed once."
          v-model="form.shortDescription"
        />
      </label>

      <hr class="divider divider--vertical" />

      <!-- Price/Amount -->
      <label for="eventPrice" class="no-shrink">
        <span :class="requiredField('eventPrice')">
          <i class="accent--text fas fa-list-alt"></i>
          <span>&nbsp;Price (USD)</span>
        </span>

        <input
          name="eventPrice"
          :disabled="disableFields"
          @input="emitFormData"
          placeholder="e.g. 100"
          type="number"
          maxlength="3"
          v-model="form.eventPrice"
        />
      </label>

      <!-- Disable Product checkbox -->
      <label
        v-if="formData.productId"
        for="disableProduct"
        class="list-item list-item--label flex"
        @click.stop="appendAndEmit({ disableProduct: !form.disableProduct })"
      >
        <input
          type="checkbox"
          name="disableProduct"
          v-model="form.disableProduct"
          @change="emitFormData"
        />

        <hr class="divider divider--vertical" />

        <span>
          <i class="fas fa-exclamation-triangle accent--text"></i>
          <span>&nbsp;Disable this product</span>
        </span>
      </label>

      <p class="warning list-item list-item--label" v-if="form.disableProduct">
        Users won't be able to purchase this item.
      </p>
      <hr class="divider divider--tiny" />
    </template>
  </fieldset>
</template>

<script>
import { listProducts } from "../models/stripe";
import state from "../state";
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "EventFormPricing",

  mixins: [FormsMixin],

  props: { isOnlineClass: Boolean },

  data() {
    return {
      stripeProducts: []
    };
  },

  computed: {
    disableFields() {
      return this.form.disableProduct || this.formData.productId;
    },

    requiredFields() {
      const fields = [
        "createProduct",
        "disableProduct",
        "shortDescription",
        "eventPrice"
      ];

      if (this.form.createProduct) fields.push("productId");
      return fields;
    },

    type() {
      return this.isOnlineClass ? "Class" : "Event";
    }
  },

  watch: {
    formData: function _onFormData(newData /* , oldD */) {
      this.hydrate(newData);
    }
  },

  mounted() {
    this.hydrate(this.formData);
  },

  methods: {
    hydrate(data) {
      // Load product data
      if (data.product) {
        const { product } = data;
        const updates = { createProduct: false, productId: data.productId };

        if (product.price) {
          updates.shortDescription = product.description;
          updates.eventPrice = product.price.unit_amount / 100;
        }

        this.appendFormData(updates);
      }
    },

    async onCreateProduct() {
      const { formData, form } = this;
      const { createProduct } = form;
      const updates = { createProduct: !createProduct };
      // pre-fill title and short desc if none exist
      if (updates.createProduct && !formData.productId) {
        const title = formData.name ? `For "${formData.name}". ` : "";
        updates.shortDescription = `${title}Billed once.`;

        // fetch stripe products if none yet
        if (!state.getState().stripeProducts.length) {
          state.stripeProducts(await listProducts());
        }

        this.stripeProducts = state.getState().stripeProducts;
      }

      // Notify parent of change
      this.appendAndEmit(updates);
    },

    onProductId(productId) {
      const form = { ...this.form };
      if (!productId) {
        delete form.productId;
        return (this.form = form);
      }

      const prod = this.stripeProducts.find(({ id }) => id === productId);
      form.productId = productId;
      form.eventPrice =
        prod.price.length === 1 ? prod.price[0].displayPrice : "Multiple";
      form.shortDescription = prod.description;

      return this.appendAndEmit(form);
    }
  }
};
</script>

<style lang="scss" scoped>
.event-form-pricing {
  .warning {
    font-size: $xs;
    font-weight: initial;
    margin-top: -$xxs;
  }
}

@media screen and (max-width: 768px) {
  .event-form-pricing {
    > * {
      width: $parent;
    }
  }
}
</style>
